import React, {Fragment} from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import ArticleImage from "../../image.js";

class ArticleBlockTabs extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            title:
            {
                default: "Title",
                label: "Title",
                type: "textarea"   
            },
            items:
            {
                addLabel: "Add tab",
                fields:
                {
                    label:
                    {
                        label: "Label"
                    },
                    content:
                    {
                        label: "Content",
                        type: "wysiwyg"   
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    ctaLabel:
                    {
                        label: "Call to action - Label",
                        placeholder: "Read more"
                    },
                    ctaLink:
                    {
                        label: "Call to action - Link",
                        placeholder: "https://"
                    },
                    ctaAlign:
                    {
                        default: "center",
                        label: "Call to action - Alignment",
                        options: {
                            left: "Left",
                            center: "Center",
                            right: "Right"
                        },
                        type: "select"
                    }
                },
                itemName: "Tab",
                label: "Tabs",
                maxItems: 10,
                onOpen: this.OnEditTab,
                type: "repeater"
            },
            alignTitle:
            {
                default: "center",
                label: "Title alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            fixedHeight:
            {
                default: true,
                label: "Fixed image height",
                type: "checkbox"
            },
            fixedValue:
            {
                default: "67%",
                displayIf: ["fixedHeight", "===", true],
                label: "Set height",
            },
            backgroundImage:
            {
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            }
        };
        this.state =
        {
            active: 0,
            numItems: 0
        };
    }

    componentDidMount()
    {
        const {id, content, onMount} = this.props;
        const {items = []} = content;
        this.setState({active: 0, numItems: items.length}, () => onMount(this, id));
    }

    componentDidUpdate()
    {
        // Switch to new tabs.
        const {active, numItems} = this.state;
        const {content} = this.props;
        const {items = []} = content;
        if (items.length > numItems || active >= items.length)
        {
            this.setState({active: items.length - 1, numItems: items.length});
        }
    }

    Item = ({ctaAlign, ctaLabel, ctaLink, content: html, image}, index) =>
    {
        const {content, disabled, editable, images, onImageUrl} = this.props;
        const {active} = this.state;
        const {fixedHeight, fixedValue} = content;
        return (
            <div className="tb-tb" data-active={index === active ? "1" : "0"} key={`tab${index}`}>
                <div className="tb-tb-cw">
                    {(image && image.length) ? (
                        <div className="tb-tb-iw" style={fixedHeight ? {padding: `0 0 ${fixedValue}`} : {}}>
                            <ArticleImage
                                className="tb-tb-i"
                                disabled={disabled}
                                editable={editable}
                                id={["items", index, "image"]}
                                imageId={image}
                                onEdit={this.OnEditableChange}
                                onUrl={onImageUrl}
                                srcref={images}
                            />
                        </div>
                    ) : ""}
                    {this.EditableContent(html, ["items", index, "content"], "tb-tb-cn", true)}
                    {this.CallToAction(ctaLabel, ctaLink, ctaAlign, ["items", index, "ctaLabel"])}
                </div>
            </div>
        );
    }

    OnEditTab = (e, index) =>
    {
        this.setState({active: index});
    }

    OnTab = (e) =>
    {
        this.setState({active: parseInt(e.currentTarget.dataset.index, 10) || 0});
    }

    render()
    {
        const {
            attributes, 
            content,
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {active} = this.state;
        const {
            alignTitle = "center",
            backgroundImage,
            backgroundOverlay,
            fixedHeight,
            items = [],
            title
        } = content || {};
        const CA = ["cb", `cb-${id}`, "tb"];
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignTitle)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        if (fixedHeight)
        {
            CA.push("_fh");
        }
        else
        {
            CA.push("_ah");
        }
        if (items.length > 0)
        {
            CA.push("_hi");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    {title ? (
                        <div className="tb-tp">
                            {this.EditableText(title, "h2", "title", "tb-t")}
                        </div>
                    ) : ""}
                    {items.length ? ( 
                    <>
                        <div className="tb-nv">
                            <div className="tb-nv-m"/>
                            <div className="tb-nv-is">
                                {items.map(({label}, index) => (
                                    <Fragment key={index}>
                                        {this.EditableText(
                                            label,
                                            "div",
                                            ["items", index, "label"],
                                            "tb-nv-i",
                                            {
                                                "data-active": index === active ? "1" : "0",
                                                "data-index": index,
                                                onClick: this.OnTab
                                            }
                                        )}
                                        <div className="tb-nv-p"/>
                                    </Fragment>
                                ))}
                            </div>
                            <div className="tb-nv-m"/>
                        </div>
                        <div className="tb-tbs">
                            <div className="tb-tbs-w">
                                {items.map((item, index) => this.Item(item, index))}
                            </div>
                        </div>
                    </>
                    ) : ""}
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockTabs;