import React, {Fragment} from "react";
import "./blockeditor.scss";
import Globals from "Class/Globals";
import {CapFloat, RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import ScrollView from "Components/UI/ScrollView";

class BlockEditor extends React.Component
{
    constructor(props)
    {
        super(props);
        this.BlockNodes = [];
        this.Debug = 0;
        this.DragBlock = false;
        this.DragIndex = -1;
        this.DragOrigin = false;
        this.Thresholds = [];
        this.state =
        {
            action: "",
            dragging: false,
            insertColumn: -1,
            insertIndex: -1,
            keys: {},
            onCanvas: false,
            selected: -1
        };
    }

    componentDidMount()
    {
        const {selectedIndex} = this.props;
        this.SetSelectedIndex(selectedIndex);
    }

    componentDidUpdate(prevProps)
    {
        const {selectedIndex: s1} = this.props;
        const {selectedIndex: s2} = prevProps;
        if (s1 !== s2)
        {
            this.SetSelectedIndex(s1);
        }
    }

    Block = ({float = -1, id, template}, index) =>
    {
        const {blocks, multiColumn, onRenderBlock} = this.props;
        const {dragging, insertColumn, insertIndex, selected} = this.state;
        const CA = ["ContentBlockContainer"];
        const IndicatorAfter = insertColumn !== 0 && index + 1 === insertIndex;// && index !== this.DragIndex && index + 1 !== this.DragIndex;
        const IndicatorBefore = (insertColumn === 0 || !index) && index !== this.DragIndex && index === insertIndex;
        const Style = multiColumn ? {width: 100 / CountBlockSiblings(blocks, index) + "%"} : {};
        if (dragging === id)
        {
            CA.push("IsDragged");
        }
        else if (dragging)
        {
            CA.push("IsPassive");
        }
        if (selected === id)
        {
            CA.push("IsSelected");
        }
        if (IndicatorAfter || IndicatorBefore)
        {
            CA.push("HasIndicator");
        }
        return (
            <Fragment key={id}>
                {IndicatorBefore && (!multiColumn || insertColumn !== 0) ? this.InsertHorizontalIndicator(`before${index}h`) : ""}
                <div
                    className={CA.join(" ")}
                    id={id}
                    onClick={this.OnBlockSelect}
                    ref={block => this.BlockNodes[index] = block}
                    style={Style}
                >
                    {IndicatorBefore && multiColumn && insertColumn === 0 ? this.InsertVerticalIndicator(`before${index}v`, 0) : ""}
                    <div className="ContentBlockWrapper">
                        {this.Debug ? <div className="ContentBlockDebug">{float}</div> : ""}
                        <div className="ContentBlockToolbar">
                            <IconButton
                                feather="Move"
                                onMouseDown={this.OnBlockDown}
                                title="Move this block"
                            />
                            <IconButton
                                disabled={!index}
                                feather="ArrowUp"
                                onClick={() => this.OnMoveBlock(index, index - 1)}
                                title="Move this block above the previous block"
                            />
                            <IconButton
                                disabled={index >= blocks.length - 1}
                                feather="ArrowDown"
                                onClick={() => this.OnMoveBlock(index, index + 2)}
                                title="Move this block below the next block"
                            />
                            <IconButton
                                feather="Copy"
                                id={id}
                                onClick={this.OnBlockDuplicate}
                                title="Duplicate this block"
                            />
                            <IconButton 
                                feather="Trash"
                                id={id}
                                onClick={this.OnBlockRemove}
                                title="Remove this block"
                            />
                        </div>
                        <div className="ContentBlock">
                            {onRenderBlock(template, id)}
                        </div>
                    </div>
                    {IndicatorAfter && multiColumn && insertColumn === 1 ? this.InsertVerticalIndicator(`before${index}v`, 1) : ""}
                </div>
                {IndicatorAfter && (!multiColumn || insertColumn !== 1) ? this.InsertHorizontalIndicator(`before${index}h`) : ""}
            </Fragment>
        );
    }

    Blocks = () =>
    {
        const {blocks} = this.props;
        if (!blocks.length)
        {
            return (
                <div className="ContentBlocksEmpty">
                    <Icon feather="PlusSquare"/>
                    <div className="ContentBlocksEmptyText">
                        Drop content blocks here
                    </div>
                </div>
            );
        }
        const Blocks = [];
        blocks.forEach((block, index) =>
        {
            Blocks.push(this.Block(block, index));
        });
        return Blocks;
    }

    GetBlockAtIndex = (index) =>
    {
        return this.props.blocks[index];
    }

    GetBlockFloat = (index) =>
    {
        const {float = -1} = this.GetBlockAtIndex(index) || {};
        return float;
    }

    InsertHorizontalIndicator = (key) =>
    {
        const {action} = this.state;
        return (
            <div key={key} className="ContentBlockInsert">
                <div className="ContentBlockInsertLine"/>
                <div className="ContentBlockInsertLabel">
                    {action}
                </div>
            </div>
        );
    }

    InsertVerticalIndicator = (key, location = 0) =>
    {
        const {action} = this.state;
        return (
            <div key={key} className={`ContentBlockInsertVertical ${location === 0 ? "Before" : "After"}`}>
                <div className="ContentBlockInsertLine"/>
                <div className="ContentBlockInsertLabel">
                    {action}
                </div>
            </div>
        );
    }

    Key = (slug) =>
    {
        return slug + "-" + (this.state.keys[slug] || 0);
    }

    NextDragScroll = () =>
    {
        if (!this.DragScroll)
        {
            return;
        }
        const Now = (new Date()).getTime();
        if (this.DragScrollLast && this.DragScrollSpeed)
        {
            const Delta = Now - this.DragScrollLast;
            const Current = this.Scroll.Content.scrollTop;
            const Set = CapFloat(Current + this.DragScrollSpeed / Delta * 500, 0, this.DragScrollHeight);
            this.Scroll.Content.scrollTop = Set;
            this.DragScrolled = Set - this.DragScrollInitial;
            if (this.DragBlock && this.LastPos)
            {
                const [DeltaX, DeltaY] = this.LastPos;
                this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY + this.DragScrolled}px)`;
            }
        }
        this.DragScrollLast = Now;
        window.requestAnimationFrame(this.NextDragScroll);
    }

    OnBlockDown = (e) =>
    {
        if (this.props.disabled || e.button !== 0 || !this.Canvas)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const Rect = this.Wrapper.getBoundingClientRect();
        this.DragBlock = e.currentTarget.parentElement.parentElement;
        this.DragIndex = this.BlockNodes.indexOf(this.DragBlock.parentElement);
        this.DragOrigin = [pageX, pageY];
        this.UpdateBlockThresholds();
        window.addEventListener("mousemove", this.OnBlockMove);
        window.addEventListener("mouseup", this.OnBlockUp);
        this.Canvas.addEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.addEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.addEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            action: "Move",
            dragging: this.DragBlock.parentElement.id
        });
        this.DragScrolled = 0;
        this.DragScrollInitial = this.Scroll.Content.scrollTop;
        this.DragScrollHeight = this.Scroll.Content.scrollHeight - this.Scroll.Content.offsetHeight;
        this.DragLimit = [Rect.top, Rect.top + Rect.height];
        this.DragScroll = true;
        this.NextDragScroll();
    }

    OnBlockDuplicate = (e, id) =>
    {
        const {blocks, disabled, onDuplicateBlock} = this.props;
        if (disabled)
        {
            return;
        }
        blocks.forEach(({id: blockId}, index) =>
        {
            if (blockId !== id)
            {
                return;
            }
            onDuplicateBlock(id, index);
        });
    }

    OnBlockRemove = (e, id) =>
    {
        Globals.DialogCreate({
            confirmLabel: "Delete Block",
            message: "Are you sure that you want to delete the selected content block?",
            onConfirm: () =>
            {
                const {blocks, onRemoveBlock, onSelectBlock} = this.props;
                const {selected} = this.state;
                if (id === selected)
                {
                    this.OnBlockUnselect();
                }
                blocks.forEach(({id: blockId}, index) =>
                {
                    if (blockId !== id)
                    {
                        return;
                    }
                    onRemoveBlock(index);
                });
            },
            title: "Delete Block",
            type: "confirm"
        });
    }

    OnBlockMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [originX, originY] = this.DragOrigin;
        const DeltaX = pageX - originX;
        const DeltaY = pageY - originY;
        this.SetDragScroll(pageY);
        this.LastPos = [DeltaX, DeltaY]; 
        this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY + this.DragScrolled}px)`;
    }

    OnBlockSelect = (e) =>
    {
        e.stopPropagation();
        const id = e.currentTarget.id;
        this.setState({selected: id}, () =>
        {
            const {blocks, onSelectBlock} = this.props;
            blocks.forEach(({id: blockId}, index) =>
            {
                if (blockId === id)
                {
                    onSelectBlock(index);
                }
            });
        });
    }

    OnBlockUnselect = () =>
    {
        if (this.state.selected === -1)
        {
            return;
        }
        this.setState({selected: -1}, () =>
        {
            const {onSelectBlock} = this.props;
            onSelectBlock(-1);
        });
    }

    OnBlockUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {blocks, onMoveBlock} = this.props;
        const {insertColumn, insertIndex} = this.state;
        const Row = CountBlockSiblings(blocks, this.DragIndex, 0, true);
        const RowIndex = Row.indexOf(this.GetBlockAtIndex(this.DragIndex));
        const Move = insertIndex >= 0 ? [this.DragIndex, insertIndex, insertColumn, Row, RowIndex] : false;
        window.removeEventListener("mousemove", this.OnBlockMove);
        window.removeEventListener("mouseup", this.OnBlockUp);
        this.Canvas.removeEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.removeEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.removeEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            dragging: false,
            insertColumn: -1,
            insertIndex: -1,
            onCanvas: false
        }, () =>
        {
            this.DragIndex = -1;
            this.DragOrigin = false;
            if (Move)
            {
                this.DragBlock.style.transform = `translate(0px, 0px)`;
                onMoveBlock(...Move);
            }
            else
            {
                // Maybe add an animation?
                this.DragBlock.style.transform = `translate(0px, 0px)`;
            }
            this.DragBlock = false;
        });
        this.DragBlock = false;
        this.DragScroll = false;
        this.DragScrollLast = false;
        this.DragScrollSpeed = 0;
        this.LastPos = false;
    }

    OnCanvasEnter = () =>
    {
        this.setState({onCanvas: true});
    }

    OnCanvasLeave = () =>
    {
        this.setState({
            insertColumn: -1,
            insertIndex: -1,
            onCanvas: false
        });
    }

    OnCanvasMove = (e) =>
    {
        const {blocks, multiColumn} = this.props;
        const {insertColumn, insertIndex} = this.state;
        let Column = 0;
        let Index = 0;
        // First block insert in multi column mode...
        if (multiColumn && !this.Thresholds.length)
        {
            if (insertColumn !== -1 || insertIndex !== 0)
            {
                this.setState({insertColumn: -1, insertIndex: 0});
            }
        }
        else if (multiColumn)
        {
            for (let i in this.Thresholds)
            {
                const [width, height, top, left] = this.Thresholds[i];
                const X = e.pageX;
                const Y = e.pageY + this.DragScrolled;
                if (Y < top || Y > top + height || X < left || X > left + width)
                {
                    continue;
                }
                const BlockIndex = parseInt(i);
                const PositionX = (X - left) / width;
                const PositionY = (Y - top) / height;
                Column = PositionX < .15 ? 0 : (PositionX > .85 ? 1 : -1);
                if (Column === -1)
                {
                    Index = PositionY < .25 ? BlockIndex - CountBlockSiblings(blocks, BlockIndex, -1) + 1 : (PositionY > .75 ? BlockIndex + CountBlockSiblings(blocks, BlockIndex, 1) : -1);
                }
                else
                {
                    Index = BlockIndex + Column;
                }
                if (Column !== insertColumn || Index !== insertIndex)
                {
                    this.setState({insertColumn: Column, insertIndex: Index});
                }
                break;
            }
        }
        else
        {
            for (let i in this.Thresholds)
            {
                if (e.pageY + this.DragScrolled < this.Thresholds[i])
                {
                    break;
                }
                Index++;
            }
            if (Index !== insertIndex)
            {
                this.setState({insertIndex: Index});
            }
        }
    }

    OnMoveBlock = (from, to) =>
    {
        const {onMoveBlock} = this.props;
        onMoveBlock(from, to);
    }

    OnTemplateDown = (e) =>
    {
        if (this.props.disabled || e.button !== 0 || !this.Canvas)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const Rect = this.Wrapper.getBoundingClientRect();
        this.DragBlock = e.currentTarget;
        this.DragOrigin = [pageX, pageY];
        this.UpdateBlockThresholds();
        window.addEventListener("mousemove", this.OnTemplateMove);
        window.addEventListener("mouseup", this.OnTemplateUp);
        this.Canvas.addEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.addEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.addEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            action: "Insert",
            dragging: e.currentTarget.dataset.slug
        });
        this.DragScrolled = 0;
        this.DragScrollInitial = this.Scroll.Content.scrollTop;
        this.DragScrollHeight = this.Scroll.Content.scrollHeight - this.Scroll.Content.offsetHeight;
        this.DragLimit = [Rect.top, Rect.top + Rect.height];
        this.DragScroll = true;
        //this.NextDragScroll();
    }

    OnTemplateMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [originX, originY] = this.DragOrigin;
        const DeltaX = pageX - originX;
        const DeltaY = pageY - originY;
        //this.SetDragScroll(pageY);
        this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY}px)`;
    }

    OnTemplateUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {onInsertBlock} = this.props;
        const {dragging, insertColumn, insertIndex, keys} = this.state;
        const Insert = insertIndex < 0 ? false : [insertIndex, dragging, RandomToken(8), {}, {}, insertColumn];
        window.removeEventListener("mousemove", this.OnTemplateMove);
        window.removeEventListener("mouseup", this.OnTemplateUp);
        this.Canvas.removeEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.removeEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.removeEventListener("mousemove", this.OnCanvasMove);
        keys[dragging] = (keys[dragging] || 0) + 1;
        this.setState({
            dragging: false,
            insertIndex: -1,
            keys,
            onCanvas: false
        }, () =>
        {
            this.DragBlock.style.transform = `translate(0px, 0px)`;
            this.DragBlock = false;
            this.DragOrigin = false;
            if (Insert)
            {
                onInsertBlock(...Insert);
            }
        });
        this.DragScroll = false;
        this.DragScrollLast = false;
        this.DragScrollSpeed = 0;
    }

    SetDragScroll = (pageY) =>
    {
        const [up, down] = this.DragLimit;
        const Range = 100;
        const Up = 1 - CapFloat((pageY - up) / Range);
        const Down = 1 - CapFloat((down - pageY) / Range);
        this.DragScrollSpeed = Up ? Up * -1 : Down;
    }

    SetSelectedIndex = (index) =>
    {
        const {blocks} = this.props;
        const Block = blocks[index];
        if (Block === undefined)
        {
            this.setState({selected: -1});
        }
        else
        {
            this.setState({selected: Block.id});
        }
    }

    Template = ({icon, name}, slug) =>
    {
        const {dragging} = this.state;
        return (
            <div className="ContentBlocksTemplateWrapper" key={slug}>
                <div
                    className="ContentBlocksTemplate"
                    onMouseDown={this.OnTemplateDown}
                    data-dragged={slug === dragging ? 1 : 0}
                    data-slug={slug}
                    key={this.Key(slug)}
                >
                    <Icon className="ContentBlocksTemplateIcon" feather={icon}/>
                    <div className="ContentBlocksTemplateLabel">{name}</div>
                </div>
            </div>
        );
    }

    Templates = () =>
    {
        const {templates} = this.props;
        const Templates = [];
        for (let slug in templates)
        {
            Templates.push(this.Template(templates[slug], slug));
        }
        return Templates;
    }

    UpdateBlockThresholds = () =>
    {
        const {multiColumn} = this.props;
        this.Thresholds = [];
        this.BlockNodes.forEach(block =>
        {
            if (!block)
            {
                return;
            }
            const {height, left, top, width} = block.getBoundingClientRect();
            this.Thresholds.push(multiColumn ? [width, height, top, left] : top + height / 2);
        });
    }

    render()
    {
        const {blocks, children, disabled, className, fullWidth, multiColumn} = this.props;
        const {dragging, onCanvas} = this.state;
        const CA = ["ContentBlocks"];
        if (!blocks.length)
        {
            CA.push("HasNoBlocks");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (dragging)
        {
            CA.push("IsDragging");
        }
        if (fullWidth)
        {
            CA.push("FullWidth");
        }
        if (multiColumn)
        {
            CA.push("MultiColumn");
        }
        if (onCanvas)
        {
            CA.push("OnCanvas");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")} onClick={this.OnBlockUnselect}>
                {children}
                <div className="ContentBlocksTemplates">
                    {this.Templates()}
                </div>
                <div className="ContentBlocksCanvasWrapper" ref={node => this.Wrapper = node}>
                    <ScrollView className="ContentBlocksCanvasScroll" ref={node => this.Scroll = node}>
                        <div className="ContentBlocksCanvas" ref={node => this.Canvas = node}>
                            {this.Blocks()}
                        </div>
                    </ScrollView>
                </div>
            </div>
        );
    }
}

export const CountBlockSiblings = (blocks = [], index = 0, direction = 0, returnRow = false) =>
{
    const {float} = blocks[index] || {};
    if (float === undefined)
    {
        return Siblings;
    }
    let Siblings = [];
    let FloatLeft = float === 0;
    let FloatRight = float === 1;
    if (direction !== 1)
    {
        for (let i = index - 1; i >= 0; i--)
        {
            const {float: siblingFloat} = blocks[i] || {};
            if (!FloatRight && siblingFloat !== 0)
            {
                break;
            }
            FloatRight = siblingFloat === 1;
            Siblings.push(blocks[i]);
        }
    }
    Siblings.push(blocks[index]);
    if (direction !== -1)
    {
        for (let i = index + 1; i < blocks.length; i++)
        {
            const {float: siblingFloat} = blocks[i] || {};
            if (!FloatLeft && siblingFloat !== 1)
            {
                break;
            }
            FloatLeft = siblingFloat === 0;
            Siblings.push(blocks[i]);
        }
    }
    return returnRow ? Siblings : Siblings.length;
}

BlockEditor.defaultProps =
{
    blocks: [],
    className: "",
    disabled: false,
    fullWidth: false,
    multiColumn: true,
    onDuplicateBlock: () => {},
    onInsertBlock: () => {},
    onMoveBlock: () => {},
    onRemoveBlock: () => {},
    onRenderBlock: () => {},
    onSelectBlock: () => {},
    selectedIndex: -1,
    templates: {},
};

export default BlockEditor;