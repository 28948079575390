import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";

class ArticleBlockHtml extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            html:
            {
                label: "HTML",
                type: "code"
            },
            css:
            {
                label: "CSS",
                language: "css",
                type: "code"
            },
            includePadding:
            {
                default: true,
                label: "Include padding",
                type: "checkbox"
            },
            backgroundImage:
            {
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            }
        };
    }

    PrefixCSS = (css) =>
    {
        if (typeof css !== "string")
        {
            return "";
        }
        const {id} = this.props;
        const Regexp = /\{[^}]*\}/gi;
        const Classes = css.match(Regexp);
        const Selectors = css.split(Regexp);
        const Prefix = `.cb-${id} `;
        let Parsed = "";
        for (let i in Classes)
        {
            const Class = Classes[i].replace(/[\n] */g, "");
            const Individual = Selectors[i].replace(/[\n] */g, "").split(",");
            const Prefixed = Individual.map(selector => Prefix + selector);
            Parsed += Prefixed + Class;
        }
        return Parsed;
    }

    render()
    {
        const {
            attributes,
            content,
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {
            backgroundImage,
            backgroundOverlay,
            css,
            html,
            includePadding
        } = content || {};
        const CA = ["cb", `cb-${id}`, "ht"];
        if (editable)
        {
            CA.push("_ce");
        }
        if (includePadding)
        {
            CA.push("_hp");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    <style>
                        {this.PrefixCSS(css)}
                    </style>
                    <div className="ht-cn" dangerouslySetInnerHTML={{__html: html}}/>
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockHtml;