import React from "react";
import IconButton from "Components/UI/IconButton";
import ImageField from "Components/UI/Field/ImageField";
import LoadImage from "Components/Layout/LoadImage";

class WysiwygImageBlock extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Controls = false;
    }

    OnChangeImage = (e, src) =>
    {
        const {block, blockProps = {}, contentState} = this.props;
        const {onChange = () => {}} = blockProps;
        onChange(block, contentState, Array.isArray(src) ? src[0] : false);
    }

    OnDelete = () =>
    {
        const {block, blockProps = {}, contentState} = this.props;
        const {onDelete = () => {}} = blockProps;
        onDelete(block, contentState);
    }

    render()
    {
        const {block, contentState} = this.props;
        const {src = ""} = contentState.getEntity(block.getEntityAt(0)).getData();
        return (
            <LoadImage
                autoAdjust={true}
                className="WysiwygImageBlock"
                src={src}
            >
                {this.Controls ? <div className="WysiwygImageBlockToolbar">
                    <ImageField
                        className="WysiwygImageBlockToolbarButton"
                        onChange={this.OnChangeImage}
                        returnImage={true}
                        minimal={true}
                    />
                    <IconButton
                        className="WysiwygImageBlockToolbarButton"
                        feather="X"
                        onClick={this.OnDelete}
                    />
                </div> : ""}
            </LoadImage>
        );
    }
}

export default WysiwygImageBlock;