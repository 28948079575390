import React from "react";
import ArticleImage from "./image";
import {ColorToString} from "Functions";

class ArticleBackground extends React.Component
{
    render()
    {
        const {
            after,
            attributes,
            children,
            color,
            className,
            fitBackground,
            overlay,
            imageId,
            style,
            ...imageProps
        } = this.props;
        const CA = ["cb-bg"];
        const ImageId = Array.isArray(imageId) ? imageId[0] : imageId;
        const Style = Object.assign({}, style);
        if (fitBackground)
        {
            CA.push("cb-fit");
        }
        else
        {
            CA.push("cb-fixed");
        }
        if (className)
        {
            CA.push(className);
        }
        if (color)
        {
            Style.background = ColorToString(color);
        }
        return (
            <div {...attributes} className={CA.join(" ")} style={Style}>
                {ImageId ? (
                    <div className="cb-bg-iw">
                        <ArticleImage imageId={ImageId} {...imageProps}/>
                        {overlay ? <div className="cb-bg-o"/> : ""}
                    </div>
                ) : ""}
                <div className="cb-cn">
                    {children}
                </div>
                {after}
            </div>
        );
    }
}

ArticleBackground.defaultProps =
{
    attributes: {},
    className: "",
    color: "",
    editable: false,
    fitBackground: false,
    imageId: 0,
    style: {}
};

export default ArticleBackground;