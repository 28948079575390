import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import {ArrayClone} from "Functions";

class ArticleBlockLinks extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            title:
            {
                default: "Title",
                label: "Title",
                type: "textarea"   
            },
            filterText:
            {
                default: "",
                label: "Filter Text",
                type: "textarea"
            },
            layout:
            {
                label: "Layout",
                options:
                {
                    twoColumns: "Two Columns",
                    threeColumns: "Three Columns"
                },
                type: "select"
            },
            links:
            {
                addLabel: "Add link",
                copy: true,
                fields:
                {
                    url:
                    {
                        label: "URL",
                        type: "url",
                        default: "https://"

                    },
                    urlBlank:
                    {
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: true
                    },
                    label:
                    {
                        allowMedia: true,
                        label: "Title",
                        type: "wysiwyg"
                    },
                    about:
                    {
                        allowMedia: true,
                        label: "About",
                        type: "wysiwyg"
                    },
                    info:
                    {
                        allowMedia: true,
                        label: "More information",
                        type: "wysiwyg"
                    },
                    callToAction:
                    {
                        label: "Call to Action"
                    },
                    inheritBackground:
                    {
                        default: true,
                        label: "Inherit background",
                        type: "checkbox"
                    },
                    backgroundColor:
                    {
                        displayIf: ["inheritBackground", "!=", true],
                        gradient: true,
                        label: "Background Color",
                        type: "color",
                        default: "transparent"
                    },
                    backgroundImage:
                    {
                        displayIf: ["inheritBackground", "!=", true],
                        label: "Background Image",
                        type: "image"
                    },
                    backgroundOverlay:
                    {
                        default: true,
                        displayIf: ["and", ["inheritBackground", "!=", true], ["backgroundImage", "!==", "empty"]],
                        label: "Background Overlay",
                        type: "checkbox"
                    },
                    taxonomies:
                    {
                        label: "Taxonomies",
                        onEditTerm: this.OnEditTerm,
                        onEditTerms: this.OnEditTerms,
                        onRemoveTaxonomy: this.OnRemoveTaxonomy,
                        onTaxonomyDescription: this.OnTaxonomyDescription,
                        onTaxonomyName: this.OnTaxonomyName,
                        onTaxonomyOrder: this.OnTaxonomyOrder,
                        pool: this.OnRequestPool,
                        type: "taxonomy",
                        default: {}
                    }
                },
                itemName: "Link",
                label: "Links",
                nameKey: "label",
                type: "repeater"
            },
            alignTitle:
            {
                default: "center",
                label: "Title alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            backgroundImage:
            {
                label: "Background Image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background Overlay",
                type: "checkbox"
            },
            itemBackgroundColor:
            {
                gradient: true,
                label: "Items Background Color",
                type: "color",
                default: "transparent"
            },
            itemBackgroundImage:
            {
                label: "Items Background Image",
                type: "image"
            },
            itemBackgroundOverlay:
            {
                default: true,
                displayIf: ["itemBackgroundImage", "!==", "empty"],
                label: "Items Background Overlay",
                type: "checkbox"
            },
        };
        this.state = {filter: {}};
    }

    OnEditTerm = (e, oldName, newName, termKey, taxonomyKey, termOrder = -1) =>
    {
        const {content, id, onField} = this.props;
        const {links = []} = content;
        links.forEach(({taxonomies = {}}) =>
        {
            if (taxonomies[taxonomyKey] === undefined || taxonomies[taxonomyKey].terms[termKey] === undefined)
            {
                return;
            }
            taxonomies[taxonomyKey].terms[termKey] = [newName || oldName, termOrder];
        });
        onField(e, "links", links, id);
    }

    OnEditTerms = (taxonomyKey, terms) =>
    {
        const {content, id, onField} = this.props;
        const {links = []} = content;
        links.forEach(({taxonomies = {}}) =>
        {
            if (taxonomies[taxonomyKey] === undefined)
            {
                return;
            }
            for (let termKey in terms)
            {
                if (taxonomies[taxonomyKey].terms[termKey] === undefined)
                {
                    continue;
                }
                taxonomies[taxonomyKey].terms[termKey] = terms[termKey];
            }
        });
        onField(null, "links", links, id);
    }

    OnRemoveTaxonomy = (e, key) =>
    {
        const {content, id, onField} = this.props;
        const {links = []} = content;
        links.forEach(({taxonomies = {}}) =>
        {
            delete taxonomies[key];
        });
        onField(e, "links", links, id);
    }

    OnRequestPool = () =>
    {
        const {content} = this.props;
        const {links = []} = content;
        const Pool = {};
        links.forEach(({taxonomies = {}}) =>
        {
            for (let taxonomyKey in taxonomies)
            {
                if (Pool[taxonomyKey] === undefined)
                {
                    Pool[taxonomyKey] = ArrayClone(taxonomies[taxonomyKey]);
                }
                else
                {
                    for (let termKey in taxonomies[taxonomyKey].terms)
                    {
                        if (Pool[taxonomyKey]?.terms[termKey] !== undefined)
                        {
                            continue;
                        }
                        Pool[taxonomyKey].terms[termKey] = taxonomies[taxonomyKey].terms[termKey];
                    }
                }
            }
        });
        return Pool;
    }

    OnRequestTaxonomies = () =>
    {
        const Pool = this.OnRequestPool();
        const Taxonomies = [];
        for (let taxonomyKey in Pool)
        {
            const {description = "", name = "", order = -1, terms = []} = Pool[taxonomyKey];
            const Terms = [];
            for (let termKey in terms)
            {
                const [termName, termOrder = -1] = Array.isArray(terms[termKey]) ? terms[termKey] : [terms[termKey], -1];
                Terms.push({key: termKey, name: termName, order: termOrder});
            }
            Terms.sort(({name: nameA, order: orderA}, {name: nameB, order: orderB}) =>
            {
                if (orderA > orderB) return +1;
                if (orderA < orderB) return -1;
                return nameA.localeCompare(nameB);
            });
            Taxonomies.push({description, key: taxonomyKey, name, order, terms: Terms})
        }
        Taxonomies.sort(({name: nameA, order: orderA}, {name: nameB, order: orderB}) =>
        {
            if (orderA > orderB) return +1;
            if (orderA < orderB) return -1;
            return nameA.localeCompare(nameB);
        });
        return Taxonomies;
    }

    OnTaxonomyDescription = (e, key, description) =>
        {
            const {content, id, onField} = this.props;
            const {links = []} = content;
            links.forEach(({taxonomies = {}}) =>
            {
                if (taxonomies[key] === undefined)
                {
                    return;
                }
                taxonomies[key].description = description;
            });
            onField(e, "links", links, id);
        }

    OnTaxonomyName = (e, oldKey, newKey, newName) =>
    {
        const {content, id, onField} = this.props;
        const {links = []} = content;
        links.forEach(({taxonomies = {}}) =>
        {
            if (taxonomies[oldKey] === undefined)
            {
                return;
            }
            taxonomies[newKey] = taxonomies[oldKey];
            taxonomies[newKey].name = newName;
            delete taxonomies[oldKey];
        });
        onField(e, "links", links, id);
    }

    OnTaxonomyOrder = (e, newOrder) =>
    {
        const {content, id, onField} = this.props;
        const {links = []} = content;
        links.forEach(({taxonomies = {}}) =>
        {
            for (let taxonomyKey in taxonomies)
            {
                taxonomies[taxonomyKey].order = newOrder.indexOf(taxonomyKey);
            }
        });
        onField(e, "links", links, id);
    }

    OnTermFilter = (e) =>
    {
        const {filter} = this.state;
        const {tm: termKey, tx: taxonomyKey} = e.currentTarget.dataset;
        if (filter[taxonomyKey] !== termKey)
        {
            filter[taxonomyKey] = termKey;
        }
        else
        {
            delete filter[taxonomyKey];
        }
        this.setState({filter});
    }

    render()
    {
        const {
            attributes, 
            content,
            disabled,
            editable,
            id,
            images,
            onImageUrl,
            style
        } = this.props;
        const {filter} = this.state;
        const {
            alignTitle = "center",
            backgroundImage,
            backgroundOverlay,
            filterText = "",
            itemBackgroundColor,
            itemBackgroundImage,
            itemBackgroundOverlay,
            layout = "twoColumns",
            links = [],
            title = ""
        } = content || {};
        const CA = ["cb", `cb-${id}`, "ln"];
        const Taxonomies = this.OnRequestTaxonomies();
        switch (layout)
        {
            case "threeColumns":
                CA.push("_layout_3_col");
                break;
            default:
                CA.push("_layout_2_col");
        }
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignTitle)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    attributes={attributes}
                    className={CA.join(" ")}
                    disabled={disabled}
                    editable={editable}
                    id="backgroundImage"
                    imageId={backgroundImage}
                    onEdit={this.OnEditableChange}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                    style={style}
                >
                    <div className="cb-cn">
                        {title ? (
                            <div className="ln-tp">
                                {this.EditableText(title, "h2", "title", "ln-t")}
                            </div>
                        ) : ""}
                        <div className="ln-cn">
                            <div className="ln-nv">
                                {this.EditableText(
                                    filterText,
                                    "div",
                                    "filterText",
                                    "ln-nv-tx"
                                )}
                                {Taxonomies.map(({description = "", key: taxonomyKey = "", name = "", terms = []}) =>
                                {
                                    return (
                                        <div className="ln-nv-t" key={taxonomyKey}>
                                            <div className="ln-nv-t-n" >{name}</div>
                                            {description ? <div className="ln-nv-t-d" >{description}</div> : ""}
                                            <div className="ln-nv-t-l">
                                                {terms.map(({key: termKey = "", name: termName = ""}) =>
                                                {
                                                    return (
                                                        <div
                                                            className="ln-nv-t-l-i"
                                                            data-active={filter["x" + String(taxonomyKey)] === "t" + String(termKey) ? "1" : "0"}
                                                            data-tx={"x" + String(taxonomyKey)}
                                                            data-tm={"t" + String(termKey)}
                                                            key={termKey}
                                                            onClick={this.OnTermFilter}
                                                        >
                                                            <div className="ln-nv-t-l-cb"/>
                                                            {termName}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="ln-l">
                                {links.map((link, index) =>
                                {
                                    const {
                                        about = "",
                                        backgroundColor,
                                        backgroundImage,
                                        backgroundOverlay,
                                        callToAction = "",
                                        info = "",
                                        inheritBackground = true,
                                        label = "",
                                        taxonomies = {},
                                        url = "",
                                        urlBlank = true
                                    } = link;
                                    for (let taxonomyKey in filter)
                                    {
                                        if (!taxonomies[taxonomyKey.substr(1)]?.terms[filter[taxonomyKey].substr(1)])
                                        {
                                            return "";
                                        }
                                    }
                                    const TaxonomyData = {};
                                    for (let taxonomyKey in taxonomies)
                                    {
                                        // Prepend taxonomy- and term keys with x and t respectively in order
                                        // for data attributes to maintain consistency..
                                        TaxonomyData[`data-x${taxonomyKey}`] = Object.keys(taxonomies[taxonomyKey].terms).map(term => "t" + term).join(",");
                                    }
                                    const Content = (
                                        <ArticleBackground
                                            className="ln-l-a-bg"
                                            color={inheritBackground ? itemBackgroundColor : backgroundColor}
                                            disabled={disabled}
                                            editable={false}
                                            imageId={inheritBackground ? itemBackgroundImage : backgroundImage}
                                            onUrl={onImageUrl}
                                            overlay={inheritBackground ? itemBackgroundOverlay : backgroundOverlay}
                                            srcref={images}
                                        >
                                            {this.EditableContent(label, ["links", index, "label"], "ln-l-t", true)}
                                            {this.EditableContent(about, ["links", index, "about"], "ln-l-cn", true)}
                                            {info || editable ? this.EditableContent(info, ["links", index, "info"], "ln-l-i", true) : ""}
                                            {callToAction ? this.EditableText(callToAction, "div", ["links", index, "callToAction"], "cb-i cb-bt") : ""}
                                        </ArticleBackground>
                                    );
                                    return editable ? (
                                        <div className="ln-l-a-w" key={index} {...TaxonomyData}>
                                            {Content}
                                        </div>
                                    ) : (
                                        <div className="ln-l-a-w" key={index} {...TaxonomyData}>
                                            {Content}
                                            <a
                                                className="ln-l-a"
                                                href={url}
                                                target={urlBlank ? "_blank" : "_top"}
                                                rel="noopener noreferrer"
                                            >{callToAction || label || about}</a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockLinks;