/*!
 *  Repeater form field containing sub fields.
 *
 *  @prop string addLabel - Optional label.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop object fields - Fields per item.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop string nameKey - Field key used for item name.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onIndex - Callback for when the repeater index focus changes.
 *  @prop function onLabel - Callback for setting an items label.
 *  @prop array value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./repeaterfield.scss";
import Globals from "Class/Globals";
import {ArrayClone, ArrayMove, CanForEach, DefaultValue, ObjectCompare, RandomToken, StripTags} from "Functions";
import IconItem from "Components/UI/IconItem"
import ListField from "Components/UI/Field/ListField";

class RepeaterField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Labels = [];
        this.Mounted = false;
        this.UpdateTimers = [];
        this.state = {
            edit: false,
            items: [],
            names: []
        };
    }

    /**
     * Parse items on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {nameKey, value} = this.props;
        this.SetItems(value, nameKey, true, true);
    }

    /**
     * Parse items when the prop is modified.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {nameKey: k1, value: v1} = this.props;
        const {nameKey: k2, value: v2} = prevProps;
        const {items} = this.state;
        if ((!ObjectCompare(v1, v2) && !ObjectCompare(v1, items)) || k1 !== k2)
        {
            this.SetItems(v1, k1, true, true);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Add a new (blank) item.
     * @return void
     */

    AddItem = () =>
    {
        const {fields} = this.props;
        const {items} = this.state;
        const Items = ArrayClone(items);
        const Item = {id: RandomToken()};
        for (let key in fields)
        {
            let {default: defaultValue, type} = fields[key];
            Item[key] = defaultValue || DefaultValue(type);
        }
        Items.push(Item);
        this.SetItems(Items, "", true, false, items =>
        {
            this.OnEdit(null, Items.length - 1);
        });
    }

    /**
     * Blur focus when a dialog is closed.
     * @return void
     */

    OnClose = () =>
    {
        const {id, onChangeIndex} = this.props;
        onChangeIndex(null, -1, id);
    }

    /**
     * Callback when a item dialog is closed.
     * @return void
     */

    OnCloseItem = () =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {id, onClose} = this.props;
        const {edit} = this.state;
        this.OnClose();
        onClose(null, edit, id);
        this.setState({edit: false});
    }

    /**
     * Callback when an items copy icon is clicked.
     * @param object e - Event object.
     * @param integer index - Item index.
     * @return void
     */

    OnCopy = (e, index) =>
    {
        const {nameKey} = this.props;
        const {items} = this.state;
        if (items[index] === undefined)
        {
            return;
        }
        const Items = ArrayClone(items);
        const Copy = ArrayClone(Items[index]);
        Copy.id = RandomToken();
        if (typeof Copy[nameKey] === "string")
        {
            Copy[nameKey] = `Copy of ${Copy[nameKey]}`;
        }
        Items.splice(index + 1, 0, Copy);
        this.SetItems(Items);
    }

    /**
     * Callback when an items edit icon is clicked.
     * @param object e - Event object.
     * @param integer index - Item index.
     * @return void
     */

    OnEdit = (e, index) =>
    {
        const {fields, id, onChangeIndex, palette, onOpen} = this.props;
        const {items} = this.state;
        const Content = items[index];
        if (!Content || !this.Mounted)
        {
            return;
        }
        onChangeIndex(e, index, id);
        onOpen(e, index, id);
        Globals.DialogCreate({
            title: "Edit item",
            type: "form",
            props: {
                content: Content,
                fields,
                id: index,
                onClose: this.OnCloseItem,
                onEdit: this.OnEditItem,
                palette
            }
        });
        this.setState({edit: index});
    }

    /**
     * Callback when an item field is edited.
     * @param object e - Event object.
     * @param string key - Field key.
     * @param mixed value - New value.
     * @param integer index - Item index.
     * @return void
     */

    OnEditItem = (e, key, value, index) =>
    {
        const {items} = this.state;
        const Item = items[index];
        if (!Item)
        {
            return;
        }
        Item[key] = value;
        this.SetItems(items);
    }

    /**
     * Callback to set the label of an item.
     * @param object e - Event object.
     * @param integer index - The item index.
     * @param string label - The item label/name.
     * @return void
     */

    OnLabel = (e, index, label) =>
    {
        const {nameKey, value} = this.props;
        this.Labels[index] = label;
        this.SetItems(value, nameKey, false, true);
    }

    /**
     * Confirm that an item should be removed and then remove it.
     * @param object e - Event object.
     * @param integer item - Item index.
     * @return void
     */

    OnRemove = (e, index) =>
    {
        const {id, onChangeIndex} = this.props;
        onChangeIndex(e, index, id);
        Globals.DialogCreate({
            type: "confirm",
            message: "Are you sure you want to remove this item?",
            confirmLabel: "Remove",
            onConfirm: () => this.RemoveItem(index),
            onClose: this.OnClose,
        });
    }

    /**
     * Callback when an item is moved in the list.
     * @param object e - The event object.
     * @param integer from - Move from index.
     * @param integer to - Move to index.
     * @return void
     */

    OnSort = (e, from, to) =>
    {
        const {items} = this.state;
        const Items = ArrayMove(items, from, to);
        this.SetItems(Items);
    }

    /**
     * Remove an item.
     * @param integer index - Item index.
     * @return void
     */

   RemoveItem = (index) =>
   {
        const {items} = this.state;
        const Items = ArrayClone(items);
        Items.splice(index, 1);
        if (this.Labels[index])
        {
            this.Labels.splice(index, 1);
        }
        this.SetItems(Items, null);
        this.OnClose();
    }

    /**
     * Update the items array
     * @param array items - New items.
     * @param string nameKey - Optional field key that sets the item label.
     * @param boolean getLabels - Whether to request item labels via callback.
     * @param boolean noCallback - Whether to skip the props callback.
     * @param func callback - Optional callback when the item has been added.
     * @return void
     */

    SetItems = (items, nameKey, getLabels = true, noCallback = false, callback) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {id, fields, itemName, onChange, onLabel} = this.props;
        const Keys = Object.keys(fields);
        const NameKey = nameKey || this.props.nameKey;
        const Names = [];
        if (CanForEach(items))
        {
            items.forEach((item, index) =>
            {
                if (typeof item !== "object")
                {
                    return;
                }
                if (!item.id)
                {
                    item.id = RandomToken();
                }
                const N = index + 1;
                Names.push(StripTags(item[NameKey] || this.Labels[index] || item[Keys[0]] || `${itemName} #${N}`));
                // Fetch this items label if a callback has been provided. Put it
                // on a timeout to avoid hammering.
                if (onLabel && getLabels)
                {
                    clearTimeout(this.UpdateTimers[index]);
                    this.UpdateTimers[index] = setTimeout(() =>
                    {
                        onLabel(null, item, id, index, this.OnLabel);
                    }, 100);
                }
            });
        }
        this.setState({items, names: Names}, () =>
        {
            if (typeof callback === "function")
            {
                callback(items);
            }
            if (!noCallback)
            {
                onChange(null, items, id);
            }
        });
    }

    /**
     * Since the repeater doesn't have a single value, it will return the name
     * of its' first item.
     * @return string - The name of the first item.
     */

    Value = () =>
    {
        const {names} = this.state;
        return names.length = names[0] || "";
    }

    render()
    {
        const {addLabel, additionalButtons, className, copy, disabled, label, maxItems, value} = this.props;
        const {names} = this.state;
        const CA = ["RepeaterField"];
        if (className) CA.push(className);
        if (disabled) CA.push("Disabled");
        if (!value.length) CA.push("Empty");
        return (
            <div className={CA.join(" ")}>
                {label ? <label>{label}</label> : ""}
                <ListField
                    additionalButtons={additionalButtons}
                    className="RepeaterFieldListField"
                    disabled={disabled}
                    onCopy={copy ? this.OnCopy : false}
                    onEdit={this.OnEdit}
                    onRemove={this.OnRemove}
                    onSort={this.OnSort}
                    value={names}
                />
                <IconItem
                    className="RepeaterFieldAdd"
                    disabled={disabled || (maxItems && names.length >= maxItems)}
                    feather="Plus"
                    label={addLabel}
                    onClick={this.AddItem}
                />
            </div>
        );
    }
}

RepeaterField.propTypes = {
    addLabel: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fields: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onChangeIndex: PropTypes.func,
    onLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    value: PropTypes.array
};

RepeaterField.defaultProps = {
    addLabel: "Add",
    className: "",
    copy: false,
    disabled: false,
    error: false,
    fields: {},
    id: "",
    itemName: "Item",
    label: "",
    maxItems: 0,
    nameKey: "",
    onChange: () => {},
    onChangeIndex: () => {},
    onClose: () => {},
    onOpen: () => {},
    onLabel: false,
    value: []
};

export default RepeaterField;