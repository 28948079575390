import React from "react";
import "./wysiwyg.scss";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, Modifier, SelectionState} from "draft-js";
import {convertFromHTML, convertToHTML} from "draft-convert";
import {RgbToHex, StringToRgb} from "Functions";
import ToolbarAlign from "./Toolbar/align";
import ToolbarBlockTypes from "./Toolbar/blockType";
import ToolbarColor from "./Toolbar/color";
import ToolbarImage from "./Toolbar/image";
import ToolbarInlines from "./Toolbar/inlines";
import ToolbarLink from "./Toolbar/link";
import ToolbarList from "./Toolbar/list";
import WysiwygImageBlock from "./Custom/image";

class Wysiwyg extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Editor = false;
        this.Toolbar =
        {
            options: ["inline", "blockType", "textAlign", "list", "link", "colorPicker"],
            inline: {component: ToolbarInlines},
            blockType: {component: ToolbarBlockTypes},
            textAlign: {component: ToolbarAlign},
            list: {component: ToolbarList},
            link: {component: ToolbarLink},
            colorPicker: {component: ToolbarColor}
        };
        this.ToolbarImage =
        {
            alignmentEnabled: false,
            alt:
            {
                present: false,
                mandatory: false
            },
            component: ToolbarImage,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
            uploadEnabled: false
        };
        this.ToolbarImageIndex = 5;
        this.state =
        {
            editorState: EditorState.createEmpty(),
            focus: false,
            html: ""
        };
    }

    componentDidMount()
    {
        const {value} = this.props;
        this.SetContent(value);
    }

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {html: v3} = this.state;
        if (v1 !== v2 && v1 !== v3)
        {
            this.SetContent(v1);
        }
    }

    Focus = () =>
    {
        if (!this.Editor)
        {
            return;
        }
        this.Editor.focusEditor();
    }

    OnBlur = (e) =>
    {
        const {id, onBlur} = this.props;
        const {html} = this.state;
        this.setState({focus: false}, () => onBlur(e, html, id));
    }

    OnChange = (editorState) =>
    {
        const {id, minimal, onChange} = this.props;
        const {html: previousHtml} = this.state;
        const CurrentContent = editorState.getCurrentContent();
        let Html;
        if (minimal === 2)
        {
            Html = CurrentContent.getPlainText();
        }
        else
        {
            Html = convertToHTML({
                blockToHTML: (block) =>
                {
                    const Props = {};
                    if (block.data["text-align"])
                    {
                        Props.align = block.data["text-align"];
                    }
                    switch (block.type)
                    {
                        case "atomic":
                            return <span {...Props}/>;
                        case "blockquote":
                            return <blockquote {...Props}/>;
                        case "code":
                            return <pre {...Props}/>;
                        case "div":
                            return <div {...Props}/>;
                        case "header-one":
                            return <h1 {...Props}/>;
                        case "header-two":
                            return <h2 {...Props}/>;
                        case "header-three":
                            return <h3 {...Props}/>;
                        case "header-four":
                            return <h4 {...Props}/>;
                        case "header-five":
                            return <h5 {...Props}/>;
                        case "header-six":
                            return <h6 {...Props}/>;
                        case "ordered-list-item":
                            return {
                                element: <li {...Props}/>,
                                nest: <ol/>
                            };
                        case "unordered-list-item":
                            return {
                                element: <li {...Props}/>,
                                nest: <ul/>
                            };
                        default:
                            //console.log("Unknown", block.type);
                            return <p {...Props}/>;
                    }
                },
                entityToHTML: (entity, originalText) =>
                {
                    switch (entity.type)
                    {
                        case "IMAGE":
                            const {src} = entity.data;
                            return <img className="cb-img" src={src}/>;
                        case "LINK":
                            const {targetOption, url} = entity.data;
                            const Target = targetOption || "_top";
                            const Rel = Target === "_blank" ? "noopener noreferer" : "";
                            return <a href={url} target={Target} rel={Rel}>{originalText}</a>;
                        default:
                            //console.log("ENTITY", entity, originalText);
                    }
                    return originalText;
                },
                styleToHTML: (style) =>
                {
                    if (style.substr(0, 5) === "color")
                    {
                        return <span style={{color: style.substr(6)}}/>;
                    }
                }
            })(CurrentContent);
        }
        //console.log("Change", Html);
        this.setState({
            editorState,
            html: Html
        }, () =>
        {
            if (Html === previousHtml)
            {
                return;
            }
            onChange(editorState, Html, id, this.Editor);
        });
    }

    OnChangeImage = (block, contentState, image) =>
    {
        console.log("Change Image", image);
    }

    OnDeleteBlock = (block, contentState) =>
    {
        // https://stackoverflow.com/questions/64236513/removing-a-block-in-draftjs
        const BlockKey = block.getKey();
        let CatchNext = false;
        let NextBlockKey;
        contentState.getBlockMap().forEach(block =>
        {
            if (CatchNext)
            {
                NextBlockKey = block.getKey();
                CatchNext = false;
            }
            else if (!NextBlockKey && BlockKey === block.getKey())
            {
                CatchNext = true;
            }
        });
        const RemoveSelection = new SelectionState({
            anchorKey: BlockKey,
            anchorOffset: block.getText().length,
            focusKey: NextBlockKey,
            focusOffset: 0
        });
        const NewContentState = Modifier.removeRange(contentState, RemoveSelection, "forward");
        const NewEditorState = EditorState.createWithContent(NewContentState);
        this.OnChange(NewEditorState);
        // https://stackoverflow.com/questions/49210165/remove-clear-all-entities-from-a-contentstate
        /*const BlockKey = block.getKey();
        const BlockText = block.getText();
        console.log("Delete", BlockKey, BlockText);
        const Selection = SelectionState.createEmpty(BlockKey);
        const UpdatedSelection = Selection.merge({
            anchorOffset: 0,
            focusOffset: BlockText.length
        });
        Modifier.applyEntity(contentState, UpdatedSelection, null);
        const NewEditorState = EditorState.createWithContent(contentState);
        this.OnChange(NewEditorState);*/
    }

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const {html} = this.state;
        this.setState({focus: true}, () => onFocus(e, html, id));
    }

    RenderBlock = (contentBlock) =>
    {
        switch(contentBlock.getType())
        {
            case "atomic":
                return {
                    component: WysiwygImageBlock,
                    editable: false,
                    props: {
                        onChange: this.OnChangeImage,
                        onDelete: this.OnDeleteBlock
                    }
                };
            default:
        }
    }
    
    Reset = () =>
    {
        const {id, onChange, value} = this.props;
        this.SetContent(value, () =>
        {
            const {editorState} = this.state;
            onChange(editorState, value, id);
        });
    }

    SetContent = (content) =>
    {
        const {minimal} = this.props;
        let SetState;
        if (minimal === 2)
        {
            SetState = content ? EditorState.createWithContent(convertFromHTML(content.replace("\n", "<br/>"))) : EditorState.createEmpty();
        }
        else
        {
            SetState = content ? EditorState.createWithContent(convertFromHTML({
                htmlToBlock: (nodeName, node) =>
                {
                    const Data = {};
                    let Type = "unstyled";
                    if (node.align)
                    {
                        Data["text-align"] = node.align;
                    }
                    switch (nodeName)
                    {
                        case "h1":
                            Type = "header-one";
                            break;
                        case "h2":
                            Type = "header-two";
                            break;
                        case "h3":
                            Type = "header-three";
                            break;
                        case "h4":
                            Type = "header-four";
                            break;
                        case "h5":
                            Type = "header-five";
                            break;
                        case "h6":
                            Type = "header-six";
                            break;
                        case "img":
                            return {type: "atomic", data: {}, text: "img"};
                        case "pre":
                            Type = "code";
                            break;
                        default:
                            return;
                    }
                    return {type: Type, data: Data};
                },
                htmlToEntity: (nodeName, node, createEntity) =>
                {
                    switch (nodeName)
                    {
                        case "a":
                            return createEntity("LINK", "MUTABLE",
                            {
                                url: node.href,
                                targetOption: node.target
                            });
                        case "img":
                            return createEntity("IMAGE", "MUTABLE",
                            {
                                src: node.src
                            })
                        default:
                    }
                },
                htmlToStyle: (nodeName, node, currentStyle) =>
                {
                    const Color = node.style.color;
                    if (Color)
                    {
                        const Hex = RgbToHex(StringToRgb(Color));
                        return currentStyle.add(`color-${Hex}`);
                    }
                    return currentStyle;
                }
            })(content)) : EditorState.createEmpty();
        }
        this.setState({
            editorState: SetState,
            html: content
        });
    }

    render()
    {
        const {allowMedia, className, disabled, flipDropdowns, minimal, placeholder} = this.props;
        const {editorState, focus} = this.state;
        const CA = ["Wysiwyg"];
        const ImageIndex = this.Toolbar.options.indexOf("image");
        if (allowMedia && ImageIndex < 0)
        {
            this.Toolbar.image = this.ToolbarImage;
            this.Toolbar.options.splice(this.ToolbarImageIndex, 0, "image")
        }
        else if (!allowMedia && ImageIndex >= 0)
        {
            delete this.Toolbar.image;
            this.Toolbar.options.splice(ImageIndex, 1);
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (minimal === 2)
        {
            CA.push("MinimalLayout NoFormatting");
        }
        else if (minimal)
        {
            CA.push("MinimalLayout");
        }
        else
        {
            CA.push("NormalLayout");
        }
        if (className)
        {
            CA.push(className);
        }
        this.Toolbar.blockType.className = flipDropdowns ? "flip" : "no-flip";
        return (
            <Editor
                blockRendererFn={this.RenderBlock}
                editorClassName="WysiwygEditor"
                editorState={editorState}
                handlePastedText={() => false}
                onBlur={this.OnBlur}
                onEditorStateChange={this.OnChange}
                onFocus={this.OnFocus}
                placeholder={placeholder}
                readOnly={disabled}
                ref={editor => this.Editor = editor}
                toolbar={this.Toolbar}
                toolbarClassName="WysiwygToolbar"
                wrapperClassName={CA.join(" ")}
            />
        );
    }
}

Wysiwyg.defaultProps =
{
    allowMedia: true,
    className: "",
    disabled: false,
    flipDropdowns: false,
    id: "",
    minimal: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    placeholder: "",
    value: ""
};

export default Wysiwyg;