/*!
 *  Image(s) form field.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop boolean multiple - Whether to allow multiple image uploads.
 *  @prop function onChange - Callback function.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imagefield.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import {ObjectCompare, RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class ImageField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Dialog = false;
        this.Mounted = false;
        this.Request = false;
        this.RequestDelay = 300;
        this.RequestTimer = false;
        this.UploadDialog = false;
        this.state =
        {
            ids: [],
            error: false,
            loading: false,
            tokens: [],
            value: []
        };
    }

    /**
     * Set initial value.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {value} = this.props;
        this.SetValue(value, true);
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {disabled: d1, value: v1} = this.props;
        const {disabled: d2, value: v2} = prevProps;
        if (d1 && d1 !== d2 && this.Dialog)
        {
            if (this.UploadDialog)
            {
                this.UploadDialog.OnAbort();
            }
            Globals.DialogDestroy(this.Dialog);
        }
        if (!ObjectCompare(v1, v2))
        {
            this.SetValue(v1, true);
        }
    }

    /**
     * Destroy dialog on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        if (this.UploadDialog)
        {
            this.UploadDialog.OnAbort();
        }
        Globals.DialogDestroy(this.Dialog);
    }

    /**
     * Clear field.
     * @param object e - The event object.
     * @param boolean noCallback - Whether to skip onChange().
     * @return void
     */

    Clear = (e, noCallback = false) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled || !this.Mounted)
        {
            return;
        }
        if (e)
        {
            e.stopPropagation();
        }
        const Empty = [];
        if (!noCallback)
        {
            onChange(e, Empty, id);
        }
        this.setState({value: Empty});
    }

    /**
     * Get the input field value.
     * @return string - Update CTA when empty, otherwise comma-seperated image names.
     */

    ImageName = () =>
    {
        const {gallery, multiple} = this.props;
        const {value, loading} = this.state;
        const Names = [];
        if (loading)
        {
            return "Loading...";
        }
        value.forEach(v =>
        {
            const {filename, hash} = v || {};
            const Name = filename || hash;
            if (!Name)
            {
                return;
            }
            Names.push(Name);
        });
        if (!Names.length)
        {
            return gallery ? (multiple ? "Pick images" : "Pick image") : (multiple ? "Upload images" : "Upload image");
        }
        return Names.join(", ");
    }

    /**
     * Output image preview(s), or feather icon when empty.
     * @return JSX - image preview.
     */

    ImagePreview = () =>
    {
        const {error, loading, value} = this.state;
        const Preview = [];
        if (loading)
        {
            return (
                <div className="ImageFieldPreview Loading" key="loading">
                    <Spinner size={24}/>
                </div>
            );
        }
        if (error)
        {
            return (
                <div className="ImageFieldPreview Error" key="error">
                    <Icon feather="AlertCircle" size={24}/>
                </div>
            );
        }
        value.forEach(v =>
        {
            const {id, urls} = v || {};
            if (!id)
            {
                return;
            }
            Preview.push(<LoadImage key={id} src={urls.thumbnail}/>);
        });
        if (!Preview.length)
        {
            return (
                <div className="ImageFieldPreview Empty" key="empty">
                    <Icon feather="Image" size={24}/>
                </div>
            );
        }
        return (
            <div
                className="ImageFieldPreview Filled"
                onClick={this.Clear}
            >
                {Preview}
                <Icon
                    feather="X"
                    size={24}
                />
            </div>
        );
    }

    /**
     * Callback when the field is clicked.
     * @return void
     */

    OnBrowse = () =>
    {
        const {disabled, gallery, multiple} = this.props;
        const {ids} = this.state;
        if (disabled)
        {
            return;
        }
        this.Dialog = Globals.DialogCreate(gallery ?
        {
            title: multiple ? "Pick images" : "Pick image",
            type: "gallery",
            props:
            {
                ref: d => this.UploadDialog = d,
                multiple: multiple,
                onClose: this.OnUploadClose,
                onSelect: this.OnUpload,
                selected: ids
            }
        } :
        {
            title: multiple ? "Upload images" : "Upload image",
            type: "upload",
            props:
            {
                accept: ["image/gif", "image/jpeg", "image/png"],
                ref: d => this.UploadDialog = d,
                multiple: multiple,
                onClose: this.OnUploadClose,
                onDone: this.OnUpload,
                onError: this.OnError
            }
        });
    }

    /**
     * Trigger onChange when the field is updated.
     * @param array input - Optional input value.
     * @param boolean noCallback - Whether to skip onChange().
     * @return void
     */

    OnChange = (input, noCallback = false) =>
    {
        const {id, onChange, returnImage} = this.props;
        const {value} = this.state;
        const Ids = [];
        const Tokens = [];
        const Value = input || value;
        Value.forEach(image =>
        {
            if (!image || !image.id)
            {
                return;
            }
            Ids.push(returnImage ? image : image.id);
            Tokens.push(image.token);
        });
        this.setState({ids: Ids, tokens: Tokens});
        // Block value update to avoid having to load file info again.
        if (!noCallback)
        {
            onChange(null, Ids, id);
        }
    }

    /**
     * Catch error messages from the file upload dialog.
     * @return void
     */

    OnError = (error) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        this.setState({error});
    }

    /**
     * Callback when uploads finish.
     * @param array uploads - An array of image objects.
     * @return void
     */

    OnUpload = (uploads) =>
    {
        const {disabled, gallery} = this.props;
        if (disabled || !this.Mounted)
        {
            return;
        }

        if (!uploads.length)
        {
            this.setState({error: gallery ? "No files selected." : "No files uploaded."});
        }
        else
        {
            this.setState({value: uploads});
            Globals.DialogDestroy(this.Dialog);
            this.OnChange(uploads);
        }
    }

    /**
     * Abort all uploads when the dialog is closed.
     * @return void
     */

    OnUploadClose = () =>
    {
        if (this.UploadDialog)
        {
            this.UploadDialog.OnAbort();
        }
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        const {value} = this.props;
        this.SetValue(value);
    }

    /**
     * Load image info from image ids.
     * @param array images - An array containing image ids.
     * @param boolean noCallback - Whether to skip onChange().
     * @return void
     */

    SetValue = (images, noCallback = false) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        if (!images.length)
        {
            return this.Clear(null, noCallback);
        }
        this.setState({
            error: false,
            loading: true,
            value: []
        });
        clearTimeout(this.RequestTimer);
        this.RequestTimer = setTimeout(() =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const Request = this.Request = RandomToken();
            API.Request("files/info", {images}, response =>
            {
                if (!this.Mounted || Request !== this.Request)
                {
                    return;
                }
                const {info, error} = response;
                const value = info || [];
                if (error)
                {
                    this.setState({error, loading: false});
                }
                else
                {
                    this.setState({value, loading: false});
                }
                this.OnChange(value, noCallback);
            });

        }, this.RequestDelay);
    }

    /**
     * Get the (first) image name as the field value.
     * @return string - The first image name.
     */

    Value = () =>
    {
        return this.ImageName() || "";
    }

    render()
    {
        const {big, className, disabled, gallery, label, minimal} = this.props;
        const {error} = this.state;
        const CA = ["Field", "ImageField"];
        if (big)
        {
            CA.push("Big");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }
        if (minimal)
        {
            CA.push("Minimal");
            return (
                <div className={CA.join(" ")} onClick={this.OnBrowse}>
                    <Icon feather={gallery ? "Folder" : "Upload"}/>
                </div>
            );

        }
        return (
            <div className={CA.join(" ")} onClick={this.OnBrowse}>
                {label ? <label>{label}</label>  : ""}
                {this.ImagePreview()}
                <div className="Input">
                    <span>{error || this.ImageName()}</span>
                    <Icon feather={gallery ? "Folder" : "Upload"}/>
                </div>
            </div>
        );
    }
}

ImageField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    gallery: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minimal: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    urls: PropTypes.bool
};

ImageField.defaultProps =
{
    className: "",
    disabled: false,
    gallery: true,
    id: "",
    label: "",
    minimal: false,
    multiple: false,
    onChange: () => {},
    returnImage: false,
    value: []
};

export default ImageField;